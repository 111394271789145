<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="default" color="#fff" type="ball-spin-fade-rotating"
  [fullScreen]="true">
  <p i18n style="color: white">Loading...</p>
</ngx-spinner>
<app-padded-panel>
  <div style="text-align: left;"
    *ngIf="!commonService.isPageEnabled('LOGIN.TechnicianLanding') && !commonService.isPageEnabled('LOGIN.StationLanding')">
    <div i18n style="border-width: 1px; text-align: left; font-size:32px; font-weight: 700; padding-left: 12px; font-family: 'Arial-BoldMT', 'Arial Bold', 'Arial', sans-serif;">Welcome</div>
    <div i18n style="text-align: left; display: flex; padding-left: 12px; font-size:24px;">Click on an application icon below to begin.</div>

    <button i18n-matTooltip id="LOGIN.MainScreen.UserManagement" mat-raised-button class="mat-elevation-z20"
      (click)="onUmaButtonClick()" aria-label="User Management"
      [style.display]="commonService.isAppEnabled('UMA')?'inline':'none'"
      matTooltip="Click on this icon to perform User Management.">
      <span i18n style="display: block; line-height: 110%;">
        &nbsp;<br>User Management<br>&nbsp;<br>&nbsp;
      </span>
      <img style="height:110px;width:110px; background-color: #c5eefa; border-radius: 10px;"
        src="assets/img/normal_u70.png" alt="Uer Management Administration">
    </button>
    <button i18n-matTooltip id="LOGIN.MainScreen.FCRA" mat-raised-button class="mat-elevation-z20"
      (click)="onFcraButtonClick()" aria-label="Inspection Fee Collection and Reconciliation"
      [style.display]="commonService.isAppEnabled('FCRA')?'inline':'none'"
      matTooltip="Click on this icon to perform Inspection Fee Collection and Reconciliation.">
      <span i18n style="display: block; line-height: 110%;">
        Inspection Fee<br>Collection and<br>Reconciliation<br>&nbsp;
      </span>
      <img style="height:110px;width:110px; background-color: #c5eefa; border-radius: 10px;"
        src="assets/img/normal_u72.png" alt="FCRA">
    </button>
    <button i18n-matTooltip id="LOGIN.MainScreen.EPBA" mat-raised-button class="mat-elevation-z20"
      (click)="onEpbaButtonClick()" aria-label="Equipment Fee Collection and Reconciliation"
      [style.display]="commonService.isAppEnabled('EPBA')?'inline':'none'"
      matTooltip="Click on this icon to perform Equipment Fee Collection and Reconciliation.">
      <span i18n style="display: block; line-height: 110%;">
        Equipment Fee<br>Collection and<br>Reconciliation<br>&nbsp;
      </span>
      <img style="height:110px;width:110px; background-color: #c5eefa; border-radius: 10px;"
        src="assets/img/normal_u173.png" alt="EPBA">
    </button>
    <button i18n-matTooltip id="LOGIN.MainScreen.ICAA" mat-raised-button class="mat-elevation-z20"
      (click)="onStationsButtonClick()" aria-label="Inspection Centre Administration"
      [style.display]="commonService.isAppEnabled('ICAA')?'inline':'none'"
      matTooltip="Click on this icon to go into Inspection Centre Administration Application .">
      <span i18n style="display: block; line-height: 110%;">
        &nbsp;<br>Inspection Centre<br>Administration<br>&nbsp;</span>
      <img style="height:110px;width:110px; background-color: #c5eefa; border-radius: 10px;"
        src="assets/img/normal_u16.svg" alt="Inspection Centre Administration">
    </button>
    <button i18n-matTooltip id="LOGIN.MainScreen.Training" mat-raised-button class="mat-elevation-z20"
      (click)="onTmqButtonClick()" aria-label="Training Materials and Quizzes"
      [style.display]="commonService.isPageEnabled('TMQ.MainEntry')?'inline':'none'"
      matTooltip="Click on this icon to enter Training.">
      <span i18n style="display: block; line-height: 110%;">
        &nbsp;<br>Training Materials<br>and Quizzes<br>&nbsp;
      </span>
      <img style="height:110px;width:110px; background-color: #c5eefa; border-radius: 10px;"
        src="assets/img/normal_u60.svg" alt="Training">
    </button>
    <button i18n-matTooltip id="LOGIN.MainScreen.ICPAA" mat-raised-button class="mat-elevation-z20"
      (click)="onIcpaaButtonClick()" aria-label="Vehicle Inspection Centre Personnel Administration"
      [style.display]="commonService.isAppEnabled('ICPAA')?'inline':'none'"
      matTooltip="Click on this icon to perform Vehicle Inspection Centre Personnel Administration Application .">
      <span i18n style="display: block; line-height: 110%;">
        Inspection Centre<br>Personnel<br>Administration<br>&nbsp;</span>
      <img style="height:110px;width:110px; background-color: #c5eefa; border-radius: 10px;"
        src="assets/img/normal_u22.svg" alt="Vehicle Inspection Centre Personnel Administration">
    </button>
    <button i18n-matTooltip id="LOGIN.MainScreen.VCA" mat-raised-button class="mat-elevation-z20"
      (click)="onVcaButtonClick()" aria-label="Vehicle Control"
      [style.display]="commonService.isAppEnabled('VCA')?'inline':'none'"
      matTooltip="Click on this icon to go to Vehicle Control Application">
      <span i18n style="display: block; line-height: 110%;text-align: center;word-wrap: break-word;
      white-space: normal;">
        &nbsp;<br>Vehicle Control<br>&nbsp;<br>&nbsp;
      </span>
      <img style="height:110px;width:110px; background-color: #c5eefa; border-radius: 10px;"
        src="assets/img/normal_u82.png" alt="Vehicle Control Application">
    </button>
    <button i18n-matTooltip id="LOGIN.MainScreen.AMT" mat-raised-button class="mat-elevation-z20"
      (click)="onAmtButtonClick()" aria-label="Audit Management and Triggers"
      [style.display]="commonService.isAppEnabled('AMT')?'inline':'none'"
      matTooltip="Click on this icon to go to Audit Management Application.">
      <span i18n style="display: block; line-height: 110%; text-align: center;word-wrap: break-word;
      white-space: normal;">
        &nbsp;<br>Audit Management <br>and Triggers<br>&nbsp;
      </span>
      <img style="height:110px;width:110px; background-color: #c5eefa; border-radius: 10px;"
        src="assets/img/normal_u81.png" alt="Audit Management">
    </button>
    <button i18n-matTooltip id="LOGIN.MainScreen.EPA" mat-raised-button class="mat-elevation-z20"
      tabindex="0" (click)="onEpaButtonClick()" aria-label="Equipment Orders"
      [style.display]="commonService.isAppEnabled('EPA')?'inline':'none'"
      matTooltip="Click on this icon to go to Equipment Orders.">
      <span i18n style="display: block; line-height: 110%; text-align: center;word-wrap: break-word;
      white-space: normal;">
        &nbsp;<br>Equipment Orders<br>&nbsp;<br>&nbsp;
      </span>
      <img style="height:110px;width:110px; background-color: #c5eefa; border-radius: 10px;"
        src="assets/img/normal_u84.png" alt="Equipment Purchase">
    </button>
    <button i18n-matTooltip id="LOGIN.MainScreen.Messages" mat-raised-button class="mat-elevation-z20"
      (click)="onSmaButtonClick()" aria-label="Messages"
      [style.display]="commonService.isPageEnabled('ICAA.Messages')?'inline':'none'"
      matTooltip="Click on this icon to perform Messages.">
      <span i18n style="display: block; line-height: 110%;">
        &nbsp;<br>Messages<br>&nbsp;<br>&nbsp;
      </span>
      <img style="height:110px;width:110px; background-color: #c5eefa; border-radius: 10px;"
        src="assets/img/normal_u26.png" alt="Messages">
    </button>
    <button i18n-matTooltip id="LOGIN.MainScreen.SMA" mat-raised-button class="mat-elevation-z20"
      (click)="onStickerButtonClick()"  aria-label="Sticker Management Application"
      [style.display]="commonService.isAppEnabled('STMA')?'inline':'none'"
      matTooltip="Click on this icon to access Sticker Management. Application">
      <span i18n style="display: block; line-height: 110%; text-align: center;word-wrap: break-word;
        white-space: normal;">
        &nbsp;<br>Sticker Management<br>&nbsp;<br>&nbsp;
      </span>
      <img style="height:110px;width:110px; background-color: #c5eefa; border-radius: 10px;"
        src="assets/img/normal_u29.png" alt="Reports">
    </button>
    <button i18n-matTooltip id="LOGIN.MainScreen.SOP" mat-raised-button class="mat-elevation-z20"
      (click) = "onSOPAndDirectivesButtonClick()" aria-label="SOPs and Directives"
      matTooltip="Click on this icon to access SOPs and Directives.">
      <span i18n style="display: block; line-height: 110%; text-align: center;word-wrap: break-word;
        white-space: normal;">
        &nbsp;<br>SOPs and<br>Directives<br>&nbsp;</span>
      <img style="height:110px;width:110px; background-color: #c5eefa; border-radius: 10px;"
        src="assets/img/normal_u169.png" alt="SOPs and Directives">
    </button>
    <button i18n-matTooltip id="LOGIN.MainScreen.VIDEO" mat-raised-button class="mat-elevation-z20"
      (click) = "onTrainingVideoButtonClick()" aria-label="Training Videos"
      matTooltip="Click on this icon to access Training Videos.">
      <span i18n style="display: block; line-height: 110%; text-align: center;word-wrap: break-word;
        white-space: normal;">
        &nbsp;<br>Training<br>Videos<br>&nbsp;</span>
      <img style="height:110px;width:110px; background-color: #c5eefa; border-radius: 10px;"
        src="assets/img/play.png" alt="Training Videos">
    </button>
    <button i18n-matTooltip id="LOGIN.MainScreen.PRA" mat-raised-button class="mat-elevation-z20"
      routerLink="/reports"  aria-label="Program Reporting Application"
      [style.display]="commonService.isAppEnabled('PRA')?'inline':'none'"
      matTooltip="Click on this icon to access reports.">
      <span i18n style="display: block; line-height: 110%; text-align: center;word-wrap: break-word;
        white-space: normal;">
        &nbsp;<br>Program Reporting<br>&nbsp;<br>&nbsp;</span>
      <img style="height:110px;width:110px; background-color: #c5eefa; border-radius: 10px;"
        src="assets/img/normal_u182.png" alt="Reports">
    </button>
    <button i18n-matTooltip id="LOGIN.MainScreen.Dashboard" mat-raised-button class="mat-elevation-z20"
      (click)="showQuickSightReport()"
      aria-label="Quick Sight"
      [style.display]="commonService.isPageEnabled('LOGIN.QuicksightDashboard')?'inline':'none'"
      matTooltip="Click on this icon to access quick sight.">
      <span i18n style="display: block; line-height: 110%;">
        &nbsp;<br>Dashboards<br>&nbsp;<br>&nbsp;
      </span>
      <img style="height:110px;width:110px; background-color: #c5eefa; border-radius: 10px;"
        src="assets/img/normal_u182.png" alt="Quick Sight">
    </button>
    <button i18n-matTooltip id="LOGIN.MainScreen.QuickSight" mat-raised-button class="mat-elevation-z20"
      (click)="showQuickSightConsole()"
      aria-label="Quick Sight Console"
      [style.display]="commonService.isAppEnabled('PRA')?'inline':'none'"
      matTooltip="Click on this icon to access quick sight.">
      <span i18n style="display: block; line-height: 110%;">
        &nbsp;<br>QuickSight<br>Console<br>&nbsp;
      </span>
      <img style="height:110px;width:110px; background-color: #c5eefa; border-radius: 10px;"
        src="assets/img/normal_u182.png" alt="Quick Sight Console">
    </button>
  </div>
  <div
    [style.display]="(commonService.isPageEnabled('LOGIN.TechnicianLanding') || commonService.isPageEnabled('LOGIN.StationLanding')) ?'inline-block':'none'">
    <div fxLayout="row wrap" fxLayoutGap="30px" fxLayout.lt-md="column">
      <div style="width: 350px">
        <img style="height:194px;width:342px;"
          src="assets/img/ICAA_Banner_Image_Phase1_Final.jpg" 
          alt="ICAA">
      </div>
      <div appwidth="100%" fxFlex="55%">
        <div
          [style.display]="commonService.isPageEnabled('LOGIN.StationLanding') ?'block':'none'">
          <h2 i18n style="border-width: 1px;
            text-align: left;
            font-size:32px;
            display: flex;
            font-weight: 700;
            font-family: 'Arial-BoldMT', 'Arial Bold', 'Arial', sans-serif;">
            Welcome to the Inspection Centre Administration Application
          </h2><br><br>
          <span i18n style="text-align: left;display: flex; font-size:24px;">
            Choose an option to begin.
          </span>
        </div>
        <div
          [style.display]="commonService.isPageEnabled('LOGIN.StationLanding') ?'none':'block'">
          <h2 i18n style="border-width: 1px;
            text-align: left;
            font-size:32px;
            display: flex;
            font-weight: 700;
            font-family: 'Arial-BoldMT', 'Arial Bold', 'Arial', sans-serif;">
            Welcome to the Inspection Centre Personnel Administration Application
          </h2><br><br>
          <span i18n style="text-align: left;display: flex; font-size:24px;">
            Congratulations for being part of the DriveON Program. Please follow the process below.<br><br>
            1. Update your STO Certification by clicking the “I want to view/edit my personal information” card.<br>
            2. Take Emissions or Safety training.<br>
            3. Complete exam modules.<br>
            4. Assign yourself to a Vehicle Inspection Centre by clicking the "I want to view/edit my personal information" card.<br>
            5. When all these steps are completed, you are able to perform inspections.
          </span>
        </div>
      </div>
    </div>
    <br/>
    <div [style.display]="commonService.isPageEnabled('LOGIN.StationLanding') && isOwner && stationLockouts != null ? 'block':'none'">
      <mat-table #stationLockoutTable [dataSource]="stationLockoutDataSource" matSort
        matSortActive="remove" matSortDirection="asc" matSortDisableClear
        class="mat-elevation-z8" style="max-height: none;" aria-label="Station Lockout">
        <ng-container matColumnDef="stationLockoutDesc">
          <mat-cell *matCellDef="let row">
            <button class="stationLockout" tabindex="0" (click)="onStationLockoutSelect(row)">
              <span i18n style="width:90%; padding-left: 10px;color: #fff;">
                Your Vehicle Inspection Centre has been locked out. Click here to find out more
              </span>
              <span style="width:10%">
                <mat-icon style="float: right;" color="#CD0000">chevron_right</mat-icon>
              </span>
            </button>
          </mat-cell>
        </ng-container>
        <mat-row *matRowDef="let row; columns: stationLockoutColumns;"></mat-row>
      </mat-table>
    </div>
    <div [style.display]="commonService.isPageEnabled('LOGIN.StationLanding') && isOwner && stationSuspensions != null ? 'block':'none'">
      <mat-table #stationSuspensionTable [dataSource]="stationSuspensionDataSource" matSort
        matSortActive="remove"matSortDirection="asc" matSortDisableClear
        class="mat-elevation-z8" style="max-height: none;" aria-label="Station Suspensions">
        <ng-container matColumnDef="stationSuspensionDesc">
          <mat-cell *matCellDef="let row">
            <button class="stationLockout" tabindex="0" (click)="onStationSuspensionSelect(row)">
              <span i18n style="width:90%; padding-left: 10px;color:#fff;">
                Your Vehicle Inspection Centre has been suspended. Click here to find out more
              </span>
              <span style="width:10%">
                <mat-icon style="float: right;" color="#CD0000">chevron_right</mat-icon>
              </span>
            </button>
          </mat-cell>
        </ng-container>
        <mat-row *matRowDef="let row; columns: stationSuspensionColumns;"></mat-row>
      </mat-table>
    </div>
    <div [style.display]="commonService.isPageEnabled('LOGIN.StationLanding') && isOwner && technicianLockouts != null ? 'block':'none'">
      <mat-table #technicianLockoutTable [dataSource]="technicianLockoutDataSource" matSort
        matSortActive="remove" matSortDirection="asc" matSortDisableClear
        class="mat-elevation-z8" style="max-height: none;" aria-label="Technician Lockouts">
        <ng-container matColumnDef="technicianLockoutDesc">
          <mat-cell *matCellDef="let row">
            <button class="technicianLockout" tabindex="0" (click)="onTechnicianLockoutSelect(row)">
              <span i18n style="width:90%; padding-left: 10px;">
                A technician assigned to your Vehicle Inspection Centre has been locked out. Click here to find out more
              </span>
              <span style="width:10%">
                <mat-icon style="float: right;" color="#CD0000">chevron_right</mat-icon>
              </span>
            </button>
          </mat-cell>
        </ng-container>
        <mat-row *matRowDef="let row; columns: technicianLockoutColumns;"></mat-row>
      </mat-table>
    </div>
    <div [style.display]="commonService.isPageEnabled('LOGIN.StationLanding') && isOwner && technicianRetrainings != null ? 'block':'none'">
      <mat-table #technicianRetrainingTable [dataSource]="technicianRetrainingDataSource" matSort
        matSortActive="remove" matSortDirection="asc" matSortDisableClear
        class="mat-elevation-z8" style="max-height: none;" aria-label="Technician Retrainings">
        <ng-container matColumnDef="technicianRetrainingDesc">
          <mat-cell *matCellDef="let row">
            <button class="technicianLockout" tabindex="0" (click)="onTechnicianRetrainingSelect(row)">
              <span i18n style="width:90%; padding-left: 10px;">
                A technician assigned to your Vehicle Inspection Centre needs Re-Training. Click here to find out more
              </span>
              <span style="width:10%">
                <mat-icon style="float: right;" color="#CD0000">chevron_right</mat-icon>
              </span>
            </button>
          </mat-cell>
        </ng-container>
        <mat-row *matRowDef="let row; columns: technicianRetrainingColumns;"></mat-row>
      </mat-table>
    </div>
    <div [style.display]="(!commonService.isPageEnabled('LOGIN.StationLanding') || !reContractingList || reContractingList.length < 1)?'none':'block'">
      <mat-table #reContractingTable [dataSource]="reContractingDataSource" matSort matSortActive="stationStatusDesc"
      matSortDirection="asc" matSortDisableClear class="mat-elevation-z8" aria-label="Station New Contract Status">
      <ng-container matColumnDef="stationStatusDesc">
        <mat-cell *matCellDef="let row">
          <button i18n class="stationLockout" tabindex="0" style="cursor: pointer;" (click)="gotoVicReContracting(row)">
            <span style="width:90%; padding-left: 10px; cursor: pointer;">SIGN NEW CONTRACT - Inspection Centre {{row.stationId}} &nbsp;&nbsp;&nbsp;>> CLICK HERE TO CONTINUE </span>
            <span style="width:10%">
              <mat-icon style="float: right;" color="primary">chevron_right</mat-icon>
            </span>
          </button>
        </mat-cell>
      </ng-container>
      <mat-row *matRowDef="let row; columns: reContractingColumns;"></mat-row>
    </mat-table>
    <mat-paginator #reContractPaginator style="width: 67%;"
      [style.display]="!reContractingList || reContractingList.length <= 3?'none':'block'"
      [pageSizeOptions]="[3, 6, 9, 12, 15]" showFirstLastButtons>
    </mat-paginator>
    </div>
    <div
      [style.display]="(!commonService.isPageEnabled('LOGIN.StationLanding') || !onboardingDetailList || onboardingDetailList.length < 1)?'none':'block'">
      <br>
      <mat-table #onboardingDetailTable [dataSource]="onboardingDetailDataSource" matSort matSortActive="remove"
        matSortDirection="asc" matSortDisableClear class="mat-elevation-z8" aria-label="Station Status">
        <ng-container matColumnDef="stationStatusDesc">
          <mat-cell *matCellDef="let row">
            <button class="onboardingStatus" tabindex="0" style="cursor: pointer;" (click)="gotoInspectionCentreOnboarding(row)">
              <span i18n *ngIf="row.stepNum !== 11" style="width:90%; padding-left: 10px; cursor: pointer;">Onboarding - Inspection Centre {{row.stationId}} in progress - Step {{row.stepNum}} of 11 completed&nbsp;&nbsp;&nbsp;>> CLICK HERE TO CONTINUE </span>
              <span i18n *ngIf="row.stepNum == 11" style="width:90%; padding-left: 10px; cursor: pointer;">{{row.stationStatusDesc}} </span>
              <span style="width:10%">
                <mat-icon style="float: right;" color="primary">chevron_right</mat-icon>
              </span>
            </button>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="cancel">
          <mat-cell *matCellDef="let row">
            &nbsp;&nbsp;
            <button class="deleteApplicationBtn" tabindex="0" type="button"
              [disabled]="row.stationStatusCd !== 'N'"
              (click)="deleteApplication(row)">
              <span i18n style="width:90%; padding-left: 10px; "> Delete Application&nbsp;</span>
            </button>
          </mat-cell>
        </ng-container>
        <mat-row *matRowDef="let row; columns: onboardingDetailColumns;"></mat-row>
      </mat-table>
      <mat-paginator #onboardingPaginator style="width: 67%;"
        [style.display]="!onboardingDetailList || onboardingDetailList.length <= 3?'none':'block'"
        [pageSizeOptions]="[3, 6, 9, 12, 15]" showFirstLastButtons></mat-paginator>
    </div>
    <button i18n-title title="I want to onboard a new Vehicle Inspection Centre Click to get started"
      (click)="onOnboardStationButtonClick()" class="card" 
      *ngIf="commonService.isPageEnabled('LOGIN.StationLanding') && isOwner"
      tabindex="0" aria-label="Owner/Staff Information">
      <mat-card style="background: transparent;box-shadow: none;">
        <mat-card-header style="justify-content: center;">
          <mat-card-title>
            <img style="height:110px;width:110px;" src="assets/img/normal_u16.svg" alt="" aria-hidden="true">
          </mat-card-title>
          <mat-card-subtitle i18n style="height: 30px; color: #333333">Owner/Staff Information</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content style="height: 60px;">
          <div i18n ngClass="mainPageCardContent">
            I want to enrol a new Vehicle Inspection Centre
          </div>
        </mat-card-content>
      </mat-card>
    </button>
    <button i18n-title title="I want to view/update information on Vehicle Inspection Centre Click to get started" 
      (click)="onViewStationdButtonClick()" class="card" style="padding: 0px;"
      *ngIf="commonService.isPageEnabled('LOGIN.StationLanding')"
      tabindex="0" aria-label="Vehicle Inspection Centre">
      <mat-card style="background: transparent;box-shadow: none;" aria-label="I want to view/update a Vehicle Inspection Centre Information">
        <mat-card-header style="justify-content: center;">
          <mat-card-title>
            <img style="height:80px;width:80px;" src="assets/img/normal_u42.png" alt="VIC Administrative Management">
          </mat-card-title>
          <mat-card-subtitle i18n style="height: 30px; color: #333333">VIC Administrative Management</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content style="height: 60px;">
          <div i18n ngClass="mainPageCardContent">
            I want to view/update information on a Vehicle Inspection Centre
          </div>
        </mat-card-content>
      </mat-card>
    </button>
    <button i18n-title title="I want to manage my technicians Click to get started"
      (click)="onManageTechniciansButtonClick()" class="card" tabindex="0" 
      aria-label="Technician Management" *ngIf="commonService.isPageEnabled('LOGIN.StationLanding')">
      <mat-card style="background: transparent;box-shadow: none;">
        <mat-card-header style="justify-content: center;">
          <mat-card-title>
            <img style="height:100px;width:100px;" src="assets/img/normal_u70.png" alt="" aria-hidden="true">
          </mat-card-title>
          <mat-card-subtitle i18n style="height: 30px; color: #333333">Technician Management</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content style="height: 60px;">
          <div i18n ngClass="mainPageCardContent">
            I want to manage Technicians
          </div>
        </mat-card-content>
      </mat-card>
    </button>
    <button i18n-title title="I want to view/edit my personal information Click to get started"
      (click)="onTechnicianInfoButtonClick()" class="card" style="padding:0px;" tabindex="0" 
      aria-label="Technician Information" *ngIf="commonService.isPageEnabled('LOGIN.TechnicianLanding')">
      <mat-card style="background: transparent;box-shadow: none;" >
        <mat-card-header style="justify-content: center;">
          <mat-card-title>
            <img style="height:100px;width:100px;" src="assets/img/normal_u22.svg" alt="" aria-hidden="true">
          </mat-card-title>
          <mat-card-subtitle i18n style="height: 30px; color: #333333">Technician Information</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content style="height: 60px;">
          <div i18n ngClass="mainPageCardContent">
            I want to view/edit my personal information
          </div>
        </mat-card-content>
      </mat-card>
    </button>
    <button i18n-title title="I want to get Safety training Click to get started"
      (click)="onTechSafTrainingButtonClick()" class="card" tabindex="0" 
      aria-label="Training Modules" *ngIf="personId && commonService.isPageEnabled('TMQ.TrainingModules')">
      <mat-card style="background: transparent;box-shadow: none;">
        <mat-card-header style="justify-content: center;">
          <mat-card-title>
            <img style="height:100px;width:100px;" src="assets/img/normal_u60.svg" alt="" aria-hidden="true">
          </mat-card-title>
          <mat-card-subtitle i18n style="height: 30px; color: #333333">Training Modules</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content style="height: 60px;">
          <div i18n ngClass="mainPageCardContent">
              I want to get Safety training
          </div>
        </mat-card-content>
      </mat-card>
    </button>
    <button i18n-title title="I want to get Emissions training Click to get started"
      (click)="onTechEmsTrainingButtonClick()" class="card" tabindex="0" aria-label="Training Modules" 
      *ngIf="commonService.isPageEnabled('TMQ.TrainingModules')">
      <mat-card style="background: transparent;box-shadow: none;">
        <mat-card-header style="justify-content: center;">
          <mat-card-title>
            <img style="height:100px;width:100px;" src="assets/img/normal_u60.svg" alt="" aria-hidden="true">
          </mat-card-title>
          <mat-card-subtitle i18n style="height: 30px; color: #333333">Training Modules</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content style="height: 60px;">
          <div i18n ngClass="mainPageCardContent">
            I want to get Emissions training
          </div>
        </mat-card-content>
      </mat-card>
    </button>

    <button i18n-title title="I want to get Structural training Click to get started"
      (click)="onTechStructuralButtonClick()" class="card" tabindex="0" aria-label="Training Modules" 
      *ngIf="personId && commonService.isPageEnabled('TMQ.TrainingModules')">
      <mat-card style="background: transparent;box-shadow: none;">
        <mat-card-header style="justify-content: center;">
          <mat-card-title>
            <img style="height:100px;width:100px;" src="assets/img/normal_u60.svg" alt="" aria-hidden="true">
          </mat-card-title>
          <mat-card-subtitle i18n style="height: 30px; color: #333333">Training Modules</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content style="height: 60px;">
          <div i18n ngClass="mainPageCardContent">
            I want to get Structural training
          </div>
        </mat-card-content>
      </mat-card>
    </button>

    <button i18n-title title="I want to change my password Click to get started"
      (click)="onChangePasswordButtonClick()" class="card" style="padding:0px;" tabindex="0" 
      aria-label="Technician Information" *ngIf="commonService.isPageEnabled('LOGIN.TechnicianLanding') || commonService.isPageEnabled('LOGIN.StationLanding')">
      <mat-card style="background: transparent;box-shadow: none;" >
        <mat-card-header style="justify-content: center;">
          <mat-card-title>
            <img style="height:100px;width:100px;" src="assets/img/change-password.png" alt="" aria-hidden="true">
          </mat-card-title>
          <mat-card-subtitle i18n style="height: 30px; color: #333333">Password</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content style="height: 60px;">
          <div i18n ngClass="mainPageCardContent">
            I want to change my password
          </div>
        </mat-card-content>
      </mat-card>
    </button>
    <button i18n-title title="I want to view my inspection transaction history Click to get started"
      (click)="onFcraReportButtonClick()" class="card" tabindex="0" aria-label="Fcra Report" 
      *ngIf="commonService.isPageEnabled('LOGIN.StationLanding') && (isOwner || isDelegate)">
      <mat-card style="background: transparent;box-shadow: none;">
        <mat-card-header style="justify-content: center;">
          <mat-card-title>
            <img style="height:100px;width:100px;" src="assets/img/Billing-Icon.png" alt="" aria-hidden="true">
          </mat-card-title>
          <mat-card-subtitle i18n style="height: 30px; color: #333333">Inspection Transaction History</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content style="height: 60px;">
          <div i18n ngClass="mainPageCardContent">
            I want to view my inspection transaction history
          </div>
        </mat-card-content>
      </mat-card>
    </button>
    <button i18n-title title="I want to purchase/view my equipment Click to get started"
      (click)="onEquipmentPurchasingButtonClick()" class="card" tabindex="0" 
      *ngIf="commonService.isPageEnabled('EPA.EPAICLandingPage')"  
      aria-label="Equipment Purchasing Application">
      <mat-card style=" background: transparent; box-shadow: none;">
        <mat-card-header style="justify-content: center;">
          <mat-card-title>
            <img style="height:90px;width:90px;" src="assets/img/normal_u84.png" alt="" aria-hidden="true">
          </mat-card-title>
          <mat-card-subtitle i18n style="height: 30px; color: #333333">Equipment Purchasing Application
          </mat-card-subtitle>
        </mat-card-header>
        <mat-card-content style="height: 60px;">
          <div i18n ngClass="mainPageCardContent">
            I want to purchase/view my equipment
          </div>
        </mat-card-content>
      </mat-card>
    </button>
    <button i18n-title title="I want to order/view my stickers"
      (click)="onStickerButtonClick()" class="card" tabindex="0" 
      *ngIf="commonService.isPageEnabled('LOGIN.StationLanding')" 
      aria-label="Sticker Management">
      <mat-card  style=" background: transparent; box-shadow: none;">
        <mat-card-header style="justify-content: center;">
          <mat-card-title>
            <img style="height:90px;width:90px;" src="assets/img/normal_u29.png" alt="" aria-hidden="true">
          </mat-card-title>
          <mat-card-subtitle i18n style="height: 30px; color: #333333">Sticker Management
          </mat-card-subtitle>
        </mat-card-header>
        <mat-card-content style="height: 60px;">
          <div i18n ngClass="mainPageCardContent">
            I want to order/view my stickers
          </div>
        </mat-card-content>
      </mat-card>
    </button>
    <button i18n-title title="Standard Operating Procedures and Directives"
      (click)="onSOPAndDirectivesButtonClick()" class="card" tabindex="0"
      aria-label="Standard Operating Procedures and Directives">
      <mat-card style="background: transparent; box-shadow: none;">
        <mat-card-header style="justify-content: center;">
          <mat-card-title>
            <img style="height:90px;width:90px;" src="assets/img/SOP.png" alt="" aria-hidden="true">
          </mat-card-title>
          <mat-card-subtitle i18n style="height: 30px; color: #333333">SOPs and Directives
          </mat-card-subtitle>
        </mat-card-header>
        <mat-card-content style="height: 60px;">
          <div i18n ngClass="mainPageCardContent">
            Standard Operating Procedures and Directives
          </div>
        </mat-card-content>
      </mat-card>
    </button>
    <button i18n-title title="Training Videos"
      (click)="onTrainingVideoButtonClick()" class="card" tabindex="0"
      aria-label="Training Videos">
      <mat-card style="background: transparent; box-shadow: none;">
        <mat-card-header style="justify-content: center;">
          <mat-card-title>
            <img style="height:90px;width:90px;" src="assets/img/play.png" alt="" aria-hidden="true">
          </mat-card-title>
          <mat-card-subtitle i18n style="height: 30px; color: #333333">Training Videos</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content style="height: 60px;">
          <div i18n ngClass="mainPageCardContent">I want to watch Training Videos</div>
        </mat-card-content>
      </mat-card>
    </button>
    <button i18n-title title=" I want to view my Vehicle Inspection Centre Report Cards Click to get started"
    (click)="onVehicleInspectionCentreReportCardsButtonClick()" class="card" tabindex="0" 
    *ngIf="commonService.isPageEnabled('LOGIN.StationLanding')"  
    aria-label="Vehicle Inspection Centre Report Cards">
    <mat-card style=" background: transparent; box-shadow: none;">
      <mat-card-header style="justify-content: center;">
        <mat-card-title>
          <img style="height:90px;width:90px;" src="assets/img/grades_report.png" alt="" aria-hidden="true">
        </mat-card-title>
        <mat-card-subtitle i18n style="height: 30px; color: #333333">Report Cards
        </mat-card-subtitle>
      </mat-card-header>
      <mat-card-content style="height: 60px;">
        <div i18n ngClass="mainPageCardContent">
          I want to view my Vehicle Inspection Centre Report Cards
        </div>
      </mat-card-content>
    </mat-card>
  </button>
  </div>
</app-padded-panel>