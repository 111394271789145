<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="default" color="#fff" type="ball-spin-fade-rotating"
  [fullScreen]="true">
  <p i18n style="color: white">Loading...</p>
</ngx-spinner>
<main class="main container-fluid">
  <fieldset class="form-group" style="border: solid; border-width: thin; border-color: #d3cdcd; padding: 10px;">
  <legend i18n style="width:auto; border:0px; margin: 0px; font-size: 14px; font-weight: bold;">Report Cards</legend>
  <br/>
  <div style="width: 98%; margin-left: 30px;">
      <div>
        <label i18n class="important_instruction" style="font-size: 16px; line-height: 1.2em; font-weight: bold;">
          Please select VIC ID/Name and Report Period, then click the "Search" button. 
        </label>
      </div>
      <br>
      <div fxLayout="row wrap" fxLayoutGap="10px" fxLayout.lt-md="column">
        <app-select-field i18n-placeholder i18n-errorMessage cmpntId="Reports.Selection.InspCent"
        [(appvalue)]="searchCriteria.stationId" [appoptions]="stations"
        errorMessage="Please select Inspection Center" appwidth="100%" fxFlex="45%" [appStyle]="{'width':'100%'}"
        placeholder="Inspection Center" areaLabel="Inspection Center">
        </app-select-field>&nbsp;&nbsp;&nbsp;&nbsp;
        <mat-form-field  fxFlex="45%">
          <select matNativeControl i18n-placeholder placeholder="Report Period" [(ngModel)]="searchCriteria.reportPeriod" aria-label="Report Period" required>
            <option style="height: 1.7em;" *ngFor="let o of reportQuarters; index as i" 
              value="{{o.code}}">{{o.value}}
            </option>
          </select>
        </mat-form-field>        
      </div>
      <button i18n mat-raised-button (click)="search()">Search</button>&nbsp;&nbsp;&nbsp;&nbsp;<span style="color:#6D00DA;">{{message}}</span>
      <br/><br>
      <fieldset style="border: solid; border-width: thin; border-color: #d3cdcd; padding: 10px;">
        <legend i18n style="width:auto; border:0px; margin: 0px; font-size: 14px; font-weight: bold;">Search Results</legend>
        <label i18n >
          Click View or Download to see the Report Card 
        </label>
          <mat-table #reportCardTable [dataSource]="reportCardDataSource" matSort class="mat-elevation-z8">
            <ng-container matColumnDef="stationId">
              <mat-header-cell i18n *matHeaderCellDef mat-sort-header>VIC #</mat-header-cell>
              <mat-cell *matCellDef="let row">
                <span i18n class="mobile-label">VIC #</span>
                <span style="display: flex">{{row.stationId}}</span>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="stationName">
              <mat-header-cell i18n *matHeaderCellDef mat-sort-header>VIC Name</mat-header-cell>
              <mat-cell *matCellDef="let row">
                <span i18n class="mobile-label">VIC Name</span>
                <span style="display: flex">{{row.stationName}}</span>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="address">
              <mat-header-cell i18n *matHeaderCellDef mat-sort-header>Address</mat-header-cell>
              <mat-cell *matCellDef="let row">
                <span i18n class="mobile-label">Address</span>
                <span style="display: flex">{{row.address}}</span>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="inspectionCategoryCd">
              <mat-header-cell i18n *matHeaderCellDef mat-sort-header>Category</mat-header-cell>
              <mat-cell *matCellDef="let row">
                <span i18n class="mobile-label">Category</span>
                <span style="display: flex">{{row.inspectionCategoryCd}}</span>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="view">
              <mat-header-cell i18n *matHeaderCellDef mat-sort-header>PDF</mat-header-cell>
              <mat-cell *matCellDef="let row">
                <span class="mobile-label"></span>
                <div>
                  <a i18n href="#" (click)="view(row);" tabindex="0">View</a>&nbsp;&nbsp;&nbsp;&nbsp;
                </div>
                <div>
                  <a i18n href="#" (click)="download(row);" tabindex="0">Download</a>
                </div>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="startDate">
              <mat-header-cell i18n *matHeaderCellDef mat-sort-header>Date</mat-header-cell>
              <mat-cell *matCellDef="let row">
                <span i18n class="mobile-label">Date</span>
                <span style="display: flex">{{row.startDate}}</span>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="quarter">
              <mat-header-cell i18n *matHeaderCellDef mat-sort-header>Quarter</mat-header-cell>
              <mat-cell *matCellDef="let row">
                <span i18n class="mobile-label">Quarter</span>
                <span style="display: flex">{{row.quarter}}</span>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="overallPerformanceGradeId">
              <mat-header-cell i18n *matHeaderCellDef mat-sort-header>Overall Grade</mat-header-cell>
              <mat-cell *matCellDef="let row">
                <span i18n class="mobile-label">Overall Grade</span>
                <span style="display: flex">{{row.overallPerformanceGradeId == 1 || row.overallPerformanceGradeId == 5 ?"A":
                                              row.overallPerformanceGradeId == 2 || row.overallPerformanceGradeId == 6 ?"B":
                                              row.overallPerformanceGradeId == 3 || row.overallPerformanceGradeId == 7 ?"C":"D" }}</span>
              </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="reportCardColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: reportCardColumns;"></mat-row>
          </mat-table>
      </fieldset>
    </div>
  </fieldset>
  </main>